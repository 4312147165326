/// <reference lib="webworker" />
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim} from 'workbox-core';
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { NetworkFirst } from 'workbox-strategies';
import { StaleWhileRevalidate } from 'workbox-strategies';
import { CacheableResponsePlugin } from 'workbox-cacheable-response';
import { CacheFirst } from 'workbox-strategies';
import {cacheNames, setCacheNameDetails} from 'workbox-core';

declare const self: ServiceWorkerGlobalScope;

clientsClaim();
console.log("Started service worker");

const expectedCaches = ['static-v3'];

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
precacheAndRoute(self.__WB_MANIFEST);

self.addEventListener('install', async event => {
  console.log('Installing New Service Worker…');
  
  // don't wait
  self.skipWaiting();
});

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');
registerRoute(
  // Return false to exempt requests from being fulfilled by index.html.
  ({ request, url }: { request: Request; url: URL }) => {
    // If this isn't a navigation, skip.
    if (request.mode !== 'navigate') {
      return false;
    }

    // If this is a URL that starts with /_, skip.
    if (url.pathname.startsWith('/_')) {
      return false;
    }

    // If this looks like a URL for a resource, because it contains
    // a file extension, skip.
    if (url.pathname.match(fileExtensionRegexp)) {
      return false;
    }

    // Return true to signal that we want to use the handler.
    return true;
  },
  createHandlerBoundToURL(process.env.PUBLIC_URL + '/index.html')
);

// An example runtime caching route for requests that aren't handled by the
// precache, in this case same-origin .png requests like those from in public/
registerRoute(
  // Add in any other file extensions or routing criteria as needed.
  ({ url }) => url.origin === self.location.origin && (url.pathname.endsWith('.png') || url.pathname.endsWith('.jpg')),
  // Customize this strategy as needed, e.g., by changing to CacheFirst.
  new StaleWhileRevalidate({
    cacheName: 'images',
    plugins: [
      // Ensure that once this runtime cache reaches a maximum size the
      // least-recently used images are removed.
      new ExpirationPlugin({ maxEntries: 50 }),
    ],
  })
);

const apiUrl = process.env["REACT_APP_API_URL"] || 'http://localhost:3001';


registerRoute(
  ({url}) =>
    url.origin === apiUrl &&
    url.pathname.startsWith('/api/cashable/'),
  new StaleWhileRevalidate({
    cacheName: 'api-cache',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      {
        cacheWillUpdate: async ({ request, response, event, state }) => {
          if (!response || response.status !== 200) {
            return null;
          }
          const cache = await caches.open('api-cache');
          console.log('Cache Opened');
          const cachedResponse = await cache.match(request);
          if (!cachedResponse) {
            return response;
          }
          const cachedText = await cachedResponse.text();
          const responseText = await response.clone().text();
          if (cachedText !== responseText) {
            return response;
          }
          return null;
        },
      },
    ],
  })
);

registerRoute(
  ({ request }) => request.mode === 'navigate',
  new StaleWhileRevalidate({
    cacheName: 'pages-cache',
    plugins: [
      {
        cacheWillUpdate: async ({ request, response, event, state }) => {
          if (!response || response.status !== 200) {
            return null;
          }
          const cache = await caches.open('pages-cache');
          console.log('Cache Opened');
          const cachedResponse = await cache.match(request);
          if (!cachedResponse) {
            return response;
          }
          const cachedText = await cachedResponse.text();
          const responseText = await response.clone().text();
          if (cachedText !== responseText) {
            return response;
          }
          return null;
        },
      },
    ],
  })
);

let notificationCount = 0;

self.addEventListener('push', (event) => {
  if (event.data) {
    console.log("Push message received:", event.data);
    const data = event.data.json();
    notificationCount++;
    if (self.navigator && 'setAppBadge' in self.navigator) {
      (self.navigator as any).setAppBadge(notificationCount).catch((error:any) => {
          console.error("Failed to set app badge:", error);
      });
    }
    event.waitUntil(
      self.registration.showNotification(data.title, {
        body: data.body,
        icon: data.icon || '%PUBLIC_URL%/snowingpine_logo.png',
        data: {
          url: data.url || '/'  // Default to root if no path is provided
        },
      })
    );
  }
});

self.addEventListener('notificationclick', (event) => {
  event.notification.close();
  var fullPath = self.location.origin + event.notification.data.url;
  notificationCount = 0;
  if (self.navigator && 'setAppBadge' in self.navigator) {
    (self.navigator as any).setAppBadge(notificationCount).catch((error:any) => {
        console.error("Failed to set app badge:", error);
    });
  }
  // focus on window if open
  event.waitUntil(
    self.clients.matchAll({ type: 'window', includeUncontrolled: true }).then((clientList) => {
      for (const client of clientList) {
        if (client.url === fullPath && 'focus' in client) {
          return client.focus();
        }
      }
      if (self.clients.openWindow) {
        return self.clients.openWindow(fullPath);
      }
    })
  );
});


// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
    self.clients.claim();
    self.clients.matchAll().then((clients) => {
      clients.forEach((client) => client.postMessage('reload-window'));
    });
    console.log("Skipped Waiting");
  }

  if (!event.data){
    return;
  }
  
  switch (event.data) {
    case 'force-activate':
      self.skipWaiting();
      self.clients.claim();
      self.clients.matchAll().then((clients) => {
        clients.forEach((client) => client.postMessage('reload-window'));
      });
      break;
    default:
      // NOOP
      break;
  }
});
 

// self.addEventListener('activate', event => {
//   // delete any caches that aren't in expectedCaches
//   // which will get rid of static-v1
//   event.waitUntil(
//     caches.keys().then(keys => Promise.all(
//       keys.map(key => {
//         if (!expectedCaches.includes(key)) {
//           return caches.delete(key);
//         }
//       })
//     )).then(() => {
//       console.log('V3 now ready to handle fetches!');
//     })
//   );   
// });


// Any other custom service worker logic can go here.